/* icons */
/* https://www.s-ings.com/typicons/ */
$icon_prefix: '.svg';
$icon_set: [
'home','cog','cart','volume-up','volume-mute','media-pause','heart',
'star','star-full-outline','star-half','star-half-outline','star-outline','three-stars','star-text',
'info','info-large','info-outline','info-circle','arrow-sync','warning','adjust-brightness','tick','times','thumbs-up','thumbs-down',
];

[class^='icon-'] {
    display: inline-flex;
    vertical-align: middle;
    text-align: center;
    width: 60%;
    height: 60%;

    -webkit-mask-size: contain;
    -webkit-mask-clip: border-box;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
   	mask-size: contain;
   	mask-clip: border-box;
   	mask-repeat: no-repeat;
   	mask-position: center center;

    background-color: #eeeeee;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;


    &.small { width: 50%; height: 50%; }
    &.medium { width: 60%; height: 60%; }
    &.large { width: 70%; height: 70%; }
    &.original { width: 100%; height: 100%; }
    &.inline { width: 1em; height: 1em; }
    &.inline-text { width: 1.1em; height: 1.1em; margin-top: -0.2em }
    &.alert { width: 1.4em; height: 1.4em; margin-top: -0.2em }
    &.cards { width: 2em; height: 2em; }
    &.three { width: 3em; height: 1.2em; }
}
@each $icon in $icon_set {    
  i.icon-#{$icon} {
    -webkit-mask-image: url('#{$icon}.svg');
    mask-image: url('#{$icon}.svg');  
  }
}