/* You can add global styles to this file, and also import other style files */

// fonts
/** =================== MONTSERRAT =================== **/

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("assets/montserrat/Montserrat-Light.eot");
    src: url("assets/montserrat/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-Light.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("assets/montserrat/Montserrat-LightItalic.eot");
    src: url("assets/montserrat/Montserrat-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-LightItalic.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: normal;
    font-style: normal;
    src: url("assets/montserrat/Montserrat-Regular.eot");
    src: url("assets/montserrat/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-Regular.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: normal;
    font-style: italic;
    src: url("assets/montserrat/Montserrat-Italic.eot");
    src: url("assets/montserrat/Montserrat-Italic.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-Italic.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-Italic.woff") format("woff");
}


/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: bold;
    font-style: normal;
    src: url("assets/montserrat/Montserrat-Bold.eot");
    src: url("assets/montserrat/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-Bold.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: bold;
    font-style: italic;
    src: url("assets/montserrat/Montserrat-BoldItalic.eot");
    src: url("assets/montserrat/Montserrat-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("assets/montserrat/Montserrat-ExtraBold.eot");
    src: url("assets/montserrat/Montserrat-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("assets/montserrat/Montserrat-ExtraBoldItalic.eot");
    src: url("assets/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("assets/montserrat/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("assets/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}


html,body {
  width: 100%;
  height: 100%;
}

html,body,main,figure,aside{ margin:0; padding:0 }

* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
}

body {
  font-family: "Montserrat";
  font-size: 14px;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overscroll-behavior: contain;
  background-color: #111;

  background-image: url('./assets/bg-stage-landscape.jpg');
  background-size: cover;
  background-attachment: fixed;



}
@media (max-width: 700px) {
  body {
    background-image: url('./assets/bg-stage-portrait.jpg');  
  }
}

h1 { font-size: 1.8em; font-weight: 600; }
h2 { font-size: 1.6em; font-weight: 600; }
h3 { font-size: 1.4em; font-weight: 600; }
h4 { font-size: 1.2em; font-weight: 600; }
p { font-size: 1em; font-weight: 400; }

button.service {
  font-size: 1.2em;
  padding: 0.6em 2em;
  margin: 0.5em 0;
  text-align: center;
  border-collapse: collapse;
  border: 2px solid rgba(255,255,255,0.6);
  border-radius: 2em;
  background: rgba(0,0,0,0.2);
  color: rgba(255,255,255,0.8);
  cursor: pointer;
  transition: background 0.5s;
  &:hover {
    background: #64bd63;
    color: #fff;
  }
}
.left { text-align: left; }
.center { text-align: center; }
.right { text-align: right; }

button,
aside,
figure{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color:#84669899;
  &.card {
    background-image: url('./assets/bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.gold {
    background-image: url('./assets/gold.jpg');
    color: #260404;    
  }
  &.trans {
    background-image: url('./assets/bg-transparent.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.mainbg {
    background-image: url('./assets/bg-stage.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.border-dark { box-shadow:inset 0 0 0 2px rgba(0,0,0,0.3); }
  &.border-lite { box-shadow:inset 0 0 0 2px rgba(255,255,255,0.2); }

  &.reflect { -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.2) 6%, transparent 25%, transparent 100%); }

  &.spd { background-image: url('./assets/spd.png'); }
  &.dbl { background-image: url('./assets/dbl.png'); }
  &.slw { background-image: url('./assets/slw.png'); }
  &.brs { background-image: url('./assets/brs.png'); }
  &.god { background-image: url('./assets/god.png'); }
}

.bg-game {
  /* background-image: url('./assets/bg-game.png'); */
  background-size: cover;
  background-repeat: no-repeat;
}

.systemfonts {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/* do not select */
.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
         pointer-events: none;
}
/* button */
aside, figure,
button, a, .button {
  outline: none;
  -webkit-tap-highlight-color: transparent; 
}
/* for absolute positions */
.abs {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: visible;
}

/* game object */
.obj {
  position:absolute;
  left:0;
  top: 0;
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
  overflow: visible;
}
.marg {
  margin-top: -50px;
  margin-left: -50px;
}

.hidden {
  display: none;
  visibility: hidden;
}
.show {
  display: block;
  visibility: visible;
}

.goo {
  -webkit-filter: url(#goo);
  filter: url(#goo);
}
.android {
  /* background-color: red; */
  #players-area {
    -webkit-filter: url(#goo);
    filter: url(#goo);
  }
  figure.player { z-index: 93; }
  figure.ai { z-index: 92; }
  figure.empty { z-index: 91; }
  figure.hole { z-index: 11; }
  figure.field { z-index: 12; }
  figure.flip { z-index: 13; }
  figure.blob { z-index: 10; }
}
.ios {
  /* background-color: green; */
  figure {
    -webkit-filter: url(#iosgoo);
    filter: url(#iosgoo);
  }
  figure.player { z-index: 93; }
  figure.ai { z-index: 92; }
  figure.empty { z-index: 91; }
  figure.hole { z-index: 11; }
  figure.field { z-index: 12; }
  figure.flip { z-index: 13; }
  figure.blob { z-index: 50; }
}


span.fx-star, span.fx-mark { display:inline-block; }

/* z index */
.main-character         { z-index: 4; }
.move-character         { z-index: 3; }
.drag-character         { z-index: 2; }

/* experimental 
.bg-color1 { background-color: #D69A80; }
.bg-color2 { background-color: #915792; }
.bg-color3 { background-color: #60497F; }
.bg-color4 { background-color: #A1C180; }
.bg-color5 { background-color: #BD5483; }
.bg-color6 { background-color: #844D54; }
.bg-color7 { background-color: #AD8D6B; }
.bg-color8 { background-color: #292369; }
.bg-color9 { background-color: #9989A0; }
*/
  
/* color perception */
$player_hex: #078B75; /* main player color */
$ai_hex: #7f2068; /* main a.i. color */
$empty_hex: #888888; /* main empty cell color */
$middle_cell_hex: #ffffff70; /* middle cell bg color */

$stroke_hex: #99999980; /* for dragging dotted stroke */
$white_hex: #ffffffCC; /* all white colors */
$black_hex: #000000; /* all black colors */


.player {
    .fill_color {  fill: $player_hex; }    
    .bg_color {  background-color: $player_hex; }    
    .text_color {  fill: $black_hex; color: $black_hex; }
    .war-text_color {  fill: $white_hex; color: $white_hex; }
    .stroke_color {  stroke: $stroke_hex; }
    .cell_color {  fill: $middle_cell_hex; }

    &.move-character .fill_color { fill: $player_hex;  }
    &.highlight::after, &.highlight::before { border-color: $player_hex; }
    &.highlight .rollover { stroke: $player_hex; }

    .my-count { stroke: $player_hex; }
    .foe-count { stroke: $ai_hex; } 
    .bust-text_color { stroke: $player_hex; }
}

.ai {
    .fill_color {  fill: $ai_hex; }
    .bg_color {  background-color: $ai_hex; } 
    .text_color {  fill: $black_hex; color: $black_hex; }
    .war-text_color {  fill: $white_hex; color: $white_hex; }
    .stroke_color {  stroke: $stroke_hex; }
    .cell_color {  fill: $middle_cell_hex; }

    &.move-character .fill_color { fill: $ai_hex;  }
    &.highlight::after, &.highlight::before { border-color: $ai_hex; }
    &.highlight .rollover { stroke: $ai_hex; }

    .my-count { stroke: $ai_hex; }
    .foe-count { stroke: $player_hex; }
    .bust-text_color { stroke: $ai_hex; }
}

.empty {
    .fill_color {  fill: $empty_hex; }
    .bg_color {  background-color: $empty_hex; } 
    .text_color {  fill: $black_hex; color: $black_hex; }
    .war-text_color {  fill: $white_hex; color: $white_hex; }
    .stroke_color {  stroke:$stroke_hex; }
    .cell_color {  fill: $middle_cell_hex; }

    &.move-character .fill_color { fill: $empty_hex;  }
    &.highlight::after,&.highlight::before { border-color: $empty_hex; }
    &.highlight .rollover { stroke: $empty_hex; }    
}

/* war colors */
$war_hex: #111111; /* when in war cell color */
$war_blink: #551111; /* when in war cell color */
$war_middle_hex: #cb0000; /* when in war cell color */
$fx_hex: #cb0000; /* fx-handicap color */

.fx_war_color { fill: $fx_hex; }

svg,
figure {

    .body-count, 
    .my-count, 
    .foe-count { transition: opacity 0.2s; }

    &:not(.war) .body-count { opacity: 1; }
    &:not(.war) .my-count { opacity: 0; }
    &:not(.war) .foe-count { opacity: 0; }

    &.war .body-count { opacity: 0; }
    &.war .my-count { opacity: 1; }
    &.war .foe-count { opacity: 1; }
    &.war .bust-text_color { stroke: #cb0000; }

    &.war.player .war_color { -webkit-animation: blink 1s infinite; animation: blink 1s infinite; }
    &.war.player .cell_color { fill: $war_middle_hex;  }
    &.war.player .bg_color { background-color: $war_hex; }

    &.war.ai .war_color { -webkit-animation: blink 1s infinite; animation: blink 1s infinite; }
    &.war.ai .cell_color { fill: $war_middle_hex;  }
    &.war.ai .bg_color { background-color: $war_hex; }
}

/* zoom animation */
@-webkit-keyframes blink {
    from { fill: $war_hex; }
    50% { fill: $war_blink; }
    100% { fill: $war_hex;}
}
@keyframes blink {
    from { fill: $war_hex; }
    50% { fill: $war_blink; }
    100% { fill: $war_hex;}
}


$hole_fill: #00000005; /* hole */
$hole_border: #333333AA; /* hole */
$hole_orbital: #00000022; 
$hole_lite: #333333;


$flip_left: #078B75CC;
$flip_right: #7f2068CC;
$flip_hex: #EE265E80; /* flip */
$flip_orbital: #00000030;

$field_fill: #00296e20; /* field */
$field_border: #9754ff50; /* field */
$field_orbital: #723aa120;
$field_lite: #eeeeee50;

.hole {
    svg path.fill_color {
      fill:  $hole_fill;
      stroke: $hole_border;
      stroke-width: 3;
      stroke-linecap: round;
    }
    .fill_lite { fill: $hole_lite; };
    .fill_orbital { fill: $hole_orbital; };
}

.flip {
    .detect_hit_player { 
        left: 20%; top: 10%; width: 30%; height: 80%; 
        border-top-left-radius: 20px; border-bottom-left-radius: 20px; }
    .detect_hit_ai { 
        left: 50%; top: 10%; width: 30%; height: 80%; 
        border-top-right-radius: 20px; border-bottom-right-radius: 20px; }
    svg path.border_color {
          fill:  none;
          stroke-width: 7;
          stroke-linecap: round;
        }
        svg path.fill_color {
          fill:  none;
          stroke-width: 2;
          stroke-linecap: round;
          stroke: $flip_orbital;
        }
        svg path.flip_left { stroke: $flip_left; };
        svg path.flip_right { stroke: $flip_right; };
}

.field {
    svg path.fill_color {
      fill:  $field_fill;
      stroke: $field_border;
      stroke-width: 7;
      stroke-linecap: round;
    }
    svg path.fill_lite {
      fill:  none;
      stroke: $field_lite;
      stroke-width: 3;
      stroke-linecap: round;
    }
    .fill_orbital { fill: $field_orbital; };
}


svg path,
svg ellipse {
  -webkit-transition: fill 1s;
  transition: fill 1s;
} 

 svg.fa-spin {
    -webkit-animation: fa-spin 5s infinite linear !important;
    animation: fa-spin 5s infinite linear !important;
  }


.rollover { 
    -webkit-transform: scale(0);
    transform: scale(0);
}
.highlight .rollover {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: zoom 1s infinite;
    animation: zoom 1s infinite;  
}

/* zoom animation */
@-webkit-keyframes zoom {
from {
-webkit-transform:scale(0);
opacity:0.6;
}
50% {
-webkit-transform:scale(1.1);
}
100% {
-webkit-transform:scale(1.1);
opacity:0;
}
}
@keyframes zoom {
from {
transform:scale(0);
opacity:0.6;
}
50% {
transform:scale(1.1);
}
100% {
transform:scale(1);
opacity:0;
}
}

// UI_checkbox
label.ui_checkbox {
        position: relative;
        margin: 0 0 0 5em;
        height: 2em;
        cursor: pointer;
        align-items: center;
        display: flex;

        span {
            position: absolute;
            left: -6em;
            top: 0;
            width: 4em;
            height: 2.2em;
            display: block;
            text-align: left;
            z-index: 0;
            margin: 0;
            padding: 0;
            pointer-events: auto !important;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 4em;
                height: 2.2em;
                background: #e0e0e0;
                z-index: 2;
                border-radius: 1.1em;
                box-shadow:inset 0 1px 3px rgb(0 0 0 / 40%);
                -webkit-transition: background 0.5s;
                transition: background 0.5s;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 2.2em;
                height: 2.2em;
                background: #eee;
                z-index: 3; 
                border-radius: 1.1em;
                box-shadow: 0 1px 3px rgb(0 0 0 / 40%);             
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-transition: -webkit-transform 0.5s;
                transition: transform 0.5s;
            }
        }
        input {
            position: absolute;
            left: -2000em;
            z-index: 1;
            opacity: 0.01;
            &:checked + span::before {
                background: #64bd63;
            }
            &:checked + span::after {
                -webkit-transform: translateX(1.8em);
                transform: translateX(1.8em);
            } 
        }

        

    }

/* icons css */
@import './assets/icons/icons.scss';

/* swiper css */
@import "~swiper/swiper-bundle";
.swiper-page {
  position: relative;
  margin: 3em auto 4em auto;
  text-align: center;
}
.swiper-pagination-bullet {
  width: 2em;
  height: 2em;
  background-color: rgba(0,0,0,0.5);      
  opacity: 1;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.swiper-pagination-bullet-active {
  background-color: #84669899;

}


